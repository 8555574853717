import React from 'react'
import styles from './MainFooter.module.css'
import Footer from '../../../components/Footer/Footer'
import { PRIVACY_POLICY, TERMS_CONDITIONS } from '../../../constants/links'
import { useTranslation } from 'react-i18next'
import logoLtr from '../../../assets/images/mhrsd-color-en.svg'
import logoRtl from '../../../assets/images/mhrsd-color-ar.svg'
import takamolLogo from '../../../assets/images/takamolLogo.svg'
import phoneIcon from '../../../assets/images/phoneIcon.svg'
import useDirection from '../../../hooks/useDirection'
import { setClass } from '../../../utils/setClass'

const MainFooter = () => {
  const { t } = useTranslation()
  const { dir } = useDirection()

  return (
    <Footer>
      <div className={styles.logo}>
        <img src={dir === 'ltr' ? logoLtr : logoRtl} alt="mhrsd-logo"/>
      </div>
      <div className={styles.takamolLogo}>
        <img src={takamolLogo} alt="takamol-logo"/>
      </div>
      <div className={setClass(styles.copyright, styles[dir])}>
        <h6>{t('rightsReserved', { year : new Date().getFullYear() })}</h6>
      </div>
      <a href={PRIVACY_POLICY} className={setClass(styles.link, styles[dir])}>{t('privacyPolicy')}</a>
      <a href={TERMS_CONDITIONS} className={setClass(styles.link, styles[dir])}>{t('termsConditions')}</a>
      <div className={styles.phoneBlock}>
        <a
          href="tel: +966920002382"
          className={setClass(styles.link, styles[dir], styles.phoneLink)}
        >
          <div className={setClass(styles.phoneIcon, styles[dir])}>
            <img src={phoneIcon} alt="phone-icon"/>
          </div>
          <h6 className={styles.phoneText}>92000 23 82</h6>
        </a>
      </div>
    </Footer>
  )
}

export default MainFooter