import React from 'react'
import styles from './Layout.module.css'
import { setClass } from '../../utils/setClass'
import useDirection from '../../hooks/useDirection'

export interface LayoutProps {
  children?: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { children } = props
  const { dir } = useDirection()

  return (
    <div className={setClass(styles.container, styles[dir])}>
      {children}
    </div>
  )
}

export default Layout