import ReactDOM from 'react-dom'
import '../src/assets/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './services/sentry'
import { FeatureNames, isFeatureAvailable } from './utils/features'

document.body.classList.add(isFeatureAvailable(FeatureNames.loewNextFont) ? 'loew' : 'arial')

ReactDOM.render(<App/>, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
