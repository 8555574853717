import { getEnvVar } from './getEnvVar'

export enum FeatureNames {
  featureTrue = 'featureTrue',
  featureFalse = 'featureFalse',
  zendeskSupport = 'zendeskSupport',
  sentryDsn = 'sentryDsn',
  sentryReplays = 'sentryReplays',
  laborSSO = 'laborSSO',
  loewNextFont = 'loewNextFont'
}

interface FeaturesConfig {
  [key: string]: string
}

export const FEATURES_CONFIG: FeaturesConfig = {
  [FeatureNames.featureTrue]: getEnvVar('REACT_APP_FEATURE_TRUE'),
  [FeatureNames.featureFalse]: getEnvVar('REACT_APP_FEATURE_FALSE'),
  [FeatureNames.zendeskSupport]: getEnvVar('REACT_APP_ENABLE_ZENDESK'),
  [FeatureNames.sentryDsn]: getEnvVar('REACT_APP_SENTRY_DSN'),
  [FeatureNames.sentryReplays]: getEnvVar('REACT_APP_SENTRY_REPLAYS'),
  [FeatureNames.laborSSO]: getEnvVar('REACT_APP_LABORER_SSO'),
  [FeatureNames.loewNextFont]: getEnvVar('REACT_APP_LOEW_NEXT_FONT'),
}

export const isFeatureAvailable = (name: FeatureNames): boolean => FEATURES_CONFIG[name] === 'true'
