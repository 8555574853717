import React from 'react'
import Header from '../../../components/Header/Header'
import logo from '../../../assets/images/logo.svg'
import Buttons from './Buttons/Buttons'

const MainHeader = () => {
  return (
    <>
      <Header>
        <a href="/"><img src={logo} alt='logo'/></a>
        <Buttons/>
      </Header>
    </>
  )
}

export default MainHeader