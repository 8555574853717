import React, { useEffect } from 'react'
import styles from './MainContent.module.css'
import { useTranslation } from 'react-i18next'
import Buttons from './Buttons/Buttons'
import ZendeskSupport from './ZendeskSupport/ZendeskSupport'
import useDirection from '../../../hooks/useDirection'
import { setClass } from '../../../utils/setClass'
import { FeatureNames, isFeatureAvailable } from '../../../utils/features'
import { zendeskInit } from '../../../utils/zendesk'

const MainContent = () => {
  const { t } = useTranslation()
  const { dir } = useDirection()

  useEffect(() => {
    zendeskInit()
  }, [])

  return (
    <main className={styles.content} data-testid='content'>
      <div className={setClass(styles.subTitle, styles[dir]) }>
        <p>{t('subTitle')}</p>
      </div>
      <div className={setClass(styles.text, styles[dir])}>
        <p>{t('text')}</p>
      </div>
      <Buttons/>
      {isFeatureAvailable(FeatureNames.zendeskSupport) && <ZendeskSupport/>}
    </main>
  )
}

export default MainContent