import React from 'react'
import Header from './MainHeader/MainHeader'
import Content from './MainContent/MainContent'
import Footer from './MainFooter/MainFooter'
import styles from './Main.module.css'

const Main = () => {
  return (
    <div className={styles.wrapper}>
      <Header/>
      <Content/>
      <Footer/>
    </div>
  )
}

export default Main