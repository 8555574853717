import React from 'react'
import styles from './Footer.module.css'

export interface FooterProps {
  children?: React.ReactNode
}

const Footer = (props: FooterProps) => {
  const { children } = props
  return (
    <footer className={styles.footer} data-testid='footer'>
      {children}
    </footer>
  )
}

export default Footer