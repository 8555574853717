import { GET_CONTEXT, INIT_SSO, SEND_LANG } from '../constants/api'
import { DASHBOARD } from '../constants/links'
import { getEnvVar } from '../utils/getEnvVar'

const defaultConfig: RequestInit = {
  credentials: 'include',
  mode: 'cors',
  cache: 'no-cache',
  redirect: 'follow',
  referrerPolicy: 'unsafe-url'
}

const MINUTE = 60 * 1000
const TEST_DELAY = 100
export const TIMEOUT = getEnvVar('NODE_ENV') === 'test' ? TEST_DELAY : MINUTE

type ResponseItem<T> = {
  type: string
  attributes: T
}

type Response<T> = {
  data: ResponseItem<T>
}

type Context = {
  account: string
  eServices: string[]
  establishment_number: null | string
  locale: 'ar' | 'en'
  personal_number: string | null
  scopes: string[]
  state: string
  userName: string | null
  workspace: string | null
  workspace_url: string | null
}

export const fetchContext  = async (): Promise<ResponseItem<Context>> => {
  try {
    const controller = new AbortController()
    const signal = controller.signal

    setTimeout(() => {
      controller.abort()
    }, TIMEOUT)

    const response = await fetch(GET_CONTEXT, {...defaultConfig, signal})

    const { data }: Response<Context> = await response.json()

    return data
  }
  catch (e) {
    throw e
  }
}

export const sendLang  = async (locale: string): Promise<any> => {
  try {
    const controller = new AbortController()
    const signal = controller.signal

    setTimeout(() => {
      controller.abort()
    }, TIMEOUT)

    const response = await fetch(SEND_LANG, {
      ...defaultConfig, signal,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          attributes: {
            locale
          },
          type: 'session'
        }
      })})

    const { data }: Response<any> = await response.json()

    return data
  }
  catch (e) {
    throw e
  }
}


type InitSSO = {
  redirect_url: string
}

export const initSSO  = async (register: boolean = false): Promise<ResponseItem<InitSSO>> => {
  try {
    const controller = new AbortController()
    const signal = controller.signal

    setTimeout(() => {
      controller.abort()
    }, TIMEOUT)

    const res = await fetch(INIT_SSO, {
      ...defaultConfig, signal,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          attributes: {
            redirect_url: DASHBOARD,
            ...(register && { register })
          },
          type: 'init'
        }
      })})

    const { data }: Response<InitSSO> = await res.json()

    return data
  }
  catch (e) {
    throw e
  }
}