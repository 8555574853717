import React from 'react'
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow.svg'
import { setClass } from '../../utils/setClass'
import styles from './Button.module.css'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import useDirection from '../../hooks/useDirection'

export interface ButtonProps {
  id?: string
  name: string
  size?: 'extraSmall' | 'small' | 'medium' | 'large'
  border?: 'left' | 'right' | 'all'
  selected?: boolean
  fullwidth?: boolean
  unsetWidth?: boolean
  unsetHeight?: boolean
  loading?: boolean
  onClick?: () => void
}

const Button = (props: ButtonProps) => {
  const {
    id,
    name,
    onClick,
    selected = false,
    fullwidth = false,
    unsetWidth = false,
    unsetHeight = false,
    loading = false,
    border = 'all',
    size = 'small'
  } = props
  const { dir } = useDirection()

  return (
    <button
      id={id}
      className={setClass(
        styles[size],
        styles[border],
        styles.button,
        selected ? styles.selected : '',
        fullwidth ? styles.fullwidth : '',
        unsetWidth ? styles.unsetWidth : '',
        unsetHeight ? styles.unsetHeight : ''
      )}
      onClick={onClick}
      disabled={loading}
      data-testid={selected ? `selected-${id}` : id}
    >
      <div className={setClass(styles.row, styles[dir])}>
        {loading
        ? <LoadingSpinner/>
        : <>
            <div>{name}</div>
            {size === 'large' && <div className={styles.icon}><ArrowIcon data-testid='arrow-icon'/></div>}
          </>}
      </div>
    </button>
  )
}

export default Button