import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ZendeskSupport.module.css'
import questionMark from '../../../../assets/images/questionMark.png'
import { setClass } from '../../../../utils/setClass'
import useDirection from '../../../../hooks/useDirection'
import { handleZendesk } from '../../../../utils/zendesk'

const ZendeskSupport = () => {
  const { t } = useTranslation()
  const { dir } = useDirection()

  return (
    <div className={setClass(styles.zendesk, styles[dir])} data-testid='zendesk'>
      <div className={setClass(styles.wrapper, styles[dir])} onClick={handleZendesk}>
        <div><img src={questionMark} alt="questionMark"/></div>
        <div className={styles.text}>{t('needHelp')}</div>
      </div>
    </div>
  );
};

export default ZendeskSupport;
