import React, { useEffect, useState } from 'react'
import preloadSrc from '../../assets/images/backgroundMin.png'

export interface LazyBackgroundProps {
  src: string
  children?: React.ReactNode
  className?: string
}

const LazyBackground = (props: LazyBackgroundProps) => {
  const { src, children, className } = props
  const [ source, setSource ] = useState(preloadSrc)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSource(src)
  }, [ src ])

  return (
    <div
      data-testid='lazy-background'
      style={{backgroundImage: `url(${source})`}}
      className={className}
    >
      {children}
    </div>
  )
}

export default LazyBackground