import { useEffect } from 'react'
import { getEnvVar } from '../utils/getEnvVar'

export default () => {
  const hotjarInitialized = () => {
    return typeof window !== 'undefined' && typeof window.hj === 'function'
  }

  const hotjarInit = () => {
    const hjid = getEnvVar('REACT_APP_HOTJAR_ID')

    if(hjid) {
      // tslint:disable-next-line:only-arrow-functions
      (function(h: Window, o: Document, t: string, j: string){
        h.hj = h.hj || function(){(h.hj.q = h.hj.q || []).push(arguments)} // tslint:disable-line:only-arrow-functions
        h._hjSettings = { hjid, hjsv: 6 }
        const a: HTMLHeadElement = o.getElementsByTagName('head')[0]
        const r: HTMLScriptElement = o.createElement('script')
        r.async = true
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
  }

  useEffect(() => {
    hotjarInit()
  }, [])

  return  { hotjarInitialized, hotjarInit }
}