import i18n from '../localization/i18n'

export const enum DOMAINS {
  dashboard = 'dashboard',
  admin = 'admin',
  um = 'um',
  lookups = 'lookups',
  operation = 'operation',
  localization = 'localization',
  musaned = 'musaned'
}


export default (domain: string) => {
  switch (domain) {
    case DOMAINS.dashboard: return i18n.t(`mainButton.${DOMAINS.dashboard}`)
    case DOMAINS.admin: return i18n.t(`mainButton.${DOMAINS.admin}`)
    default: return i18n.t('mainButton.default')
  }
}