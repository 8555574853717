import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button/Button'
import { useTranslation } from 'react-i18next'
import styles from './Buttons.module.css'
import { DASHBOARD, LOGIN, REGISTER } from '../../../../constants/links'
import { fetchContext, initSSO } from '../../../../api/auth.api'
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner'
import { FeatureNames, isFeatureAvailable } from '../../../../utils/features'
import getRedirectButtonText from '../../../../utils/getRedirectButtonText'

const Buttons = () => {
  const { t, i18n } = useTranslation()
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ state, setState ] = useState<string>('')
  const [ domain, setDomain ] = useState<string>('')
  const isGuest = !!state ? state === 'guest' : true

  const handleRedirect = (url: string, register: boolean = false) => () => {
    if(isFeatureAvailable(FeatureNames.laborSSO) && isGuest){
      return initSSO(register)
        .then(({ attributes: { redirect_url } }) => {
          return window.location.href = redirect_url
        })
        .catch(() => undefined)
    }

    return window.location.href = url
  }

  useEffect(() => {
    setLoading(true)
    fetchContext()
      .then(({ attributes: { state, locale, workspace_url} }) => {
        setDomain(workspace_url?.replace(/^https?:\/\//, '')
          .replace(/^www\./, '')
          .replace(/\..*/, '') || '')
        setState(state)
        setLoading(false)
        i18n.changeLanguage(locale)
      })
      .catch(() => {
        setState('')
        setLoading(false)
      })
  }, []);


  return (
    <div className={styles.buttons} data-testid='content-buttons'>
      {loading
      ? <LoadingSpinner/>
      : <>
          <Button
            id='login'
            name={isGuest ? t('loginBtn') : getRedirectButtonText(domain)}
            size='large'
            unsetWidth
            unsetHeight
            onClick={handleRedirect(isGuest ? LOGIN + DASHBOARD : DASHBOARD)}
          />
          {isGuest && <Button
            id='register'
            name={t('registerBtn')}
            size='medium'
            unsetWidth
            unsetHeight
            onClick={handleRedirect(REGISTER + DASHBOARD, true)}
          />}
        </>}
    </div>
  )
}

export default Buttons