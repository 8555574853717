import React  from 'react'
import styles from './assets/App.module.css'
import Layout from './containers/Layout/Layout'
import Main from './pages/Main/Main'
import { I18nextProvider } from 'react-i18next'
import i18nInstance from '../src/localization/i18n'
import LazyBackground from './components/LazyBackground/LazyBackground'
import src from './assets/images/background.png'
import useHotjar from './hooks/useHotjar'

const App = () => {
  useHotjar()

  return (
    <LazyBackground src={src} className={styles.app}>
      <I18nextProvider i18n={i18nInstance}>
        <Layout>
          <Main/>
        </Layout>
      </I18nextProvider>
    </LazyBackground>
  )
}

export default App
