import Button from '../../../../components/Button/Button'
import { useTranslation } from 'react-i18next'
import { sendLang } from '../../../../api/auth.api'
import useDirection from '../../../../hooks/useDirection'
import { changeZendeskLang } from '../../../../utils/zendesk'
import { FeatureNames, isFeatureAvailable } from '../../../../utils/features'

const Buttons = () => {
  const { i18n } = useTranslation()
  const { dir } = useDirection()

  const handleChangeLanguage = (lang: string) => () => {
    i18n.changeLanguage(lang)
    if(isFeatureAvailable(FeatureNames.zendeskSupport)){
      changeZendeskLang()
    }
    sendLang(lang)
  }

  return (
    <div data-testid='header-buttons'>
      <Button
        id='english'
        name='English'
        border='left'
        size='small'
        selected={dir === 'ltr'}
        onClick={handleChangeLanguage('en')}
      />
      <Button
        id='arabic'
        name='عربي'
        border='right'
        size='extraSmall'
        selected={dir === 'rtl'}
        onClick={handleChangeLanguage('ar')}
      />
    </div>
  )
}

export default Buttons