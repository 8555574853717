import i18n from 'i18next'
import { getEnvVar } from './getEnvVar'
import { FeatureNames, isFeatureAvailable } from './features'

export const changeZendeskLang = () => {
  try {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        position: {
          horizontal: i18n.language === 'en' ? 'right' : 'left'
        }
      })
      window.zE('webWidget', 'setLocale', i18n.language === 'en' ? 'en-uk' : 'ar')
    }
  }
  // tslint:disable-next-line: no-empty
  catch (e) {}
}

export const zendeskOptions = () => {
  try {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: '#0d91b8',
          launcherText: '#fff'
        },
        offset: {
          vertical: '40px'
        },
        position: {
          horizontal: i18n.language === 'en' ? 'right' : 'left'
        }
      }
    })
    window.zE('webWidget', 'setLocale', i18n.language === 'en' ? 'en-uk' : 'ar',)
    window.zE('webWidget', 'hide');
  }
  // tslint:disable-next-line: no-empty
  catch (e) {}
}

export const zendeskForeignOptions = () => {
  try {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: {
          vertical: '73px',
          mobile: {
            vertical: window.matchMedia('(max-width: 767px)').matches ? '292px' : '73px'
          }
        }
      }
    })
  }
  // tslint:disable-next-line: no-empty
  catch (e) {}
}

export const zendeskInit = () => {
  const url = `https://static.zdassets.com/ekr/snippet.js?key=${getEnvVar('REACT_APP_ZENDESK_KEY')}`
  const zendeskScript = document.createElement('script')
  zendeskScript.id = 'ze-snippet'
  document.body.appendChild(zendeskScript)
  if(isFeatureAvailable(FeatureNames.zendeskSupport)){
    zendeskScript.onload = () => zendeskOptions()
  } else {
    zendeskScript.onload = () => zendeskForeignOptions()
  }
  zendeskScript.src = url
}

export const handleZendesk = () => {
  try {
    window.zE.activate({ hideOnClose: true })
  }
  // tslint:disable-next-line: no-empty
  catch (e) {}
}