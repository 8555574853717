import * as Sentry from '@sentry/react'
import { getEnvVar } from '../utils/getEnvVar'
import { FeatureNames, isFeatureAvailable } from '../utils/features'

const dsn = getEnvVar('REACT_APP_SENTRY_DSN')

if(dsn) {
  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    environment: getEnvVar('REACT_APP_SPACE'),

    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay by feature flag for sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: isFeatureAvailable(FeatureNames.sentryReplays) ? 1.0 : 0,
  })
}

export default Sentry