import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      title: "ISTIQDAM PLATFORM",
      subTitle: "It is a platform that allows Istiqdam sector establishments to complete the services of the labor sector with ease",
      text: "The services provided through Istiqdam platform aim to enhance digital transformation, facilitate the delivery of services, speed decision-making, increase the level of transparency, raise quality and improve the experiences of beneficiaries.",
      loginBtn: "Sign in",
      registerBtn: "Register",
      dashboardBtn: "Go to dashboard",
      privacyPolicy: "Privacy Policy",
      termsConditions: "Terms and Conditions",
      rightsReserved: "© {{year}} All Rights Reserved.",
      needHelp: "Need help?",
      mainButton: {
        default: "Go to Unified Portal",
        admin: "Go to Service Management",
        dashboard: "Go to Services",
      }
    }
  },
  ar: {
    translation: {
      title: "منصة إستقدام",
      subTitle: "منصة تتيح لمنشآت قطاع الإستقدام استكمال خدمات قطاع  العمل بكل يسر و سهولة",
      text: "تهدف الخدمات المقدمة من خلال منصة إستقدام إلى تعزيز التحول الرقمي ، و تسهيل تقديم الخدمات ، و تسريع اتخاذ القرارات ، وزيادة مستوى الشفافية ، ورفع الجودة وتحسين تجارب المستفيدين",
      loginBtn: "تسجيل الدخول",
      registerBtn: "تسجيل جديد",
      dashboardBtn: " الذهاب الى لوحة التحكم",
      privacyPolicy: "سياسة الخصوصية",
      termsConditions: "شروط الاستخدام (منصة استقدام)",
      rightsReserved: "جميع الحقوق محفوظة {{year}}",
      needHelp: "مساعدة",
      mainButton: {
        default: "العودة للصفحة الرئيسية",
        admin: "الذهاب الى إدارة الخدمات",
        dashboard: "الذهاب الى الخدمات",
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ar",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
