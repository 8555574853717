import { getEnvVar } from '../utils/getEnvVar'

// Login
export const LOGIN = `${getEnvVar('REACT_APP_AUTH_SPA')}/sign-in?default_workspace=individual&cross_domain_redirect_uri=`
// Register
export const REGISTER = `${getEnvVar('REACT_APP_AUTH_SPA')}/sign-up?default_workspace=individual&cross_domain_redirect_uri=`
// Dashboard
export const DASHBOARD = `${getEnvVar('REACT_APP_DASHBOARD')}`
// Privacy and Policy
export const PRIVACY_POLICY = `${getEnvVar('REACT_APP_PUBLIC_SPA')}/privacy-policy`
// Terms Conditions
export const TERMS_CONDITIONS = `${getEnvVar('REACT_APP_PUBLIC_SPA')}/terms-and-conditions`