import React from 'react'
import styles from './Header.module.css'

type HeaderProps = {
  children?: React.ReactNode
}

const Header = (props: HeaderProps) => {
  const { children } = props
  return (
    <header className={styles.header} data-testid='header'>
      {children}
    </header>
  )
}

export default Header